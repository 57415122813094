<template>
  <b-card
    class="stockstocktakingitem-edit-wrapper"
  >
    <!-- form -->
    <b-form id="stockstocktakingitemForm" class="edit-form mt-2">
      <b-row>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label=""
            label-for="ss_id"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="ss_id"
              size="sm"
              v-model="stockstocktakingitem.ss_id"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="商品Id"
            label-for="product_id"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="product_id"
              size="sm"
              v-model="stockstocktakingitem.product_id"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="商品编码(69码)"
            label-for="code"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="code"
              size="sm"
              v-model="stockstocktakingitem.code"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="商品名称"
            label-for="name"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="name"
              size="sm"
              v-model="stockstocktakingitem.name"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="生产日期"
            label-for="production_date"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="production_date"
              size="sm"
              v-model="stockstocktakingitem.production_date"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="调整数量"
            label-for="qty"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="qty"
              size="sm"
              v-model="stockstocktakingitem.qty"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="库存状态 默认0"
            label-for="stockstatus"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="stockstatus"
              size="sm"
              v-model="stockstocktakingitem.stockstatus"
            />
          </b-form-group>
        </b-col>
                <b-col
          cols="12"
          class="mt-50"
        >
          <b-button
            variant="primary"
            class="mr-1"
            @click="save"
          >
            保存
          </b-button>
          <b-button
            variant="outline-secondary"
            @click="cancel"
          >
            取消
          </b-button>
        </b-col>
      </b-row>
    </b-form>
    <!--/ form -->
  </b-card>
</template>

<script>
import {
  BCard, BMedia, BAvatar, BCardText, BForm, BRow, BCol, BFormGroup, BFormInput, BImg, BFormFile, BLink, BButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import {onUnmounted, ref} from '@vue/composition-api'
import router from "@/router";
import store from "@/store";
import stockstocktakingitemStore from './stockstocktakingitemStore'
// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { toTime, toDate, getCodeOptions, getCode, getCodeLabel, getCodeColor } from '@core/utils/filter'

export default {
  components: {
    BCard,
    BMedia,
    BAvatar,
    BCardText,
    BForm,
    BLink,
    BImg,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormFile,
    vSelect,
    useToast,
  },
  data() {
    return {
      id: ref(0),
      stockstocktakingitem: ref({}),
    }
  },
  setup() {
    const toast = useToast()

    // Register module
    if (!store.hasModule('stockstocktakingitem')) store.registerModule('stockstocktakingitem', stockstocktakingitemStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('stockstocktakingitem')) store.unregisterModule('stockstocktakingitem')
    })

    const edit = function() {
      store.dispatch('stockstocktakingitem/edit', {id: this.id}).then(res => {
        this.stockstocktakingitem = res.data.data
      })
    }

    const view = function() {
      store.dispatch('stockstocktakingitem/view', {id: this.id}).then(res => {
        this.stockstocktakingitem = res.data.data
      })
    }

    const cancel = function() {
      this.$router.go(-1)
    }

    const save = function() {
      store.dispatch('stockstocktakingitem/save', this.stockstocktakingitem).then(res => {
        toast.success('数据已保存!')
        this.$router.push({ name: 'apps-${classVar}-list'});
      })
    }

    return {
      edit,
      view,
      cancel,
      save,

      getCodeOptions,
      getCode,
      getCodeLabel,
      getCodeColor,
    }
  },
  created() {
    this.id = this.$route.query.id || 0;
    this.edit()
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
